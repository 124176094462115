// For some packages (`videojs-contrib-quality-levels`) build for nodejs and trying get via global, set the window as global.
// it's not global = window, because on client it's ok all times like this and event if some how it's running on server side it's will be ok.
window.global = window;

require('angular-bootstrap-colorpicker/css/colorpicker.css');
require('video.js/dist/video-js.css');
require('ng-tags-input/build/ng-tags-input.css');
require('angular-input-stars/angular-input-stars.css');
require('quill/dist/quill.core.css');
require('quill/dist/quill.bubble.css');
require('quill/dist/quill.snow.css');
require('angular');
require('angular-animate');
require('angular-cookies');
require('angular-resource');
require('angular-ui-bootstrap');
require('angular-translate');
require('angular-route');
window._ = require('underscore');
require('./bower-libs/angular-underscore');
require('angular-sanitize');
require('ng-csv');
require('ngstorage');
require(['video.js', 'videojs-hls-quality-selector'], function (videojs) {
    window.videojs = videojs?.default || videojs;
});
require('videojs-playlist');
require('angular-bootstrap-colorpicker');
require('ng-tags-input');
window.QRCode = require('./bower-libs/qrcode');
require('./bower-libs/angular-qr');
require('angulartics');
require('ngSmoothScroll');
require('angular-messages');
require('./bower-libs/tel');
require('./bower-libs/metadatalite');
require('angular-ui-validate');
require('angulartics-google-analytics');
require('aws-sdk');
require('angular-input-stars');
window.Quill = require('quill');
window.makeVideoPlayableInline = require('iphone-inline-video');
window.pdfMake = require('./bower-libs/pdfmake');
require('./bower-libs/vfs_fonts');
require('./bower-libs/fileSaver');
window.jsPDF = require('jspdf');
require('angular-ui-router');
require('./bower-libs/angular-fullscreen');
require('ng-sortable');
require('angular-jwt');
require('videojs-contrib-quality-levels');
require('videojs-hls-quality-selector');
require('./bower-libs/google-plus-signin');
require('satellizer');
require('angular-bootstrap-multiselect');

global.Buffer = global.Buffer || require('buffer').Buffer;
